function Footer() {
  const generalData = require("./../../datas/general.json");
  return (
    <div className="footer">
      <div className="footer_links">
        <a
          href={generalData.info.github.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={generalData.info.github.logo}
            alt="logo github"
            id="logo_github_footer"
          />
        </a>
        <a
          href={generalData.info.linkedin.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={generalData.info.linkedin.logo}
            alt="logo linkedin"
            id="logo_linkedin_footer"
          />
        </a>
      </div>
      <h2>Réalisé par Lucas THIRON</h2>
      <img src={generalData.studies[4].logo} alt="logo OC"></img>
    </div>
  );
}

export default Footer;
