import ProjectModal from "./../Modal";

function ProjectCard({ title, year, text, technologies, pictures, link }) {
  return (
    <div className="projectsinglecard">
      <div className="projectpicturediv">
        <img
          src={pictures[1]}
          className="projectpic"
          alt="capture projet"
        ></img>
      </div>
      <ProjectModal
        title={title}
        year={year}
        text={text}
        technologies={technologies}
        pictures={pictures}
        link={link}
      />
    </div>
  );
}

export default ProjectCard;
