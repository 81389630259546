import { useState, useEffect, useRef, useMemo } from "react";
import ServiceCard from "../ServiceCard";
import FadeService from "../FadeService";

function Services() {
  const generalData = require("./../../datas/general.json");

  //function dedicated to know if targetRef is visible screen and display it with fade
  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setInView(true);
  };

  const options = useMemo(() => {
    return { root: null, rootMargin: "0px", threshold: 0.5 };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, options]);

  return (
    <div className="services" ref={targetRef}>
      <h1 id="servicetitle">Mes Services</h1>
      <div className="servicescards">
        <FadeService visible={inView}>
          <ServiceCard
            title={generalData.services.back.title}
            text={generalData.services.back.text}
            tools={[
              { logo: generalData.technologies[0].logo },
              { logo: generalData.technologies[2].logo },
              { logo: generalData.technologies[7].logo },
              { logo: generalData.technologies[8].logo },
              { logo: generalData.technologies[9].logo },
            ]}
          />
        </FadeService>
        <FadeService visible={inView}>
          <ServiceCard
            title={generalData.services.front.title}
            text={generalData.services.front.text}
            tools={[
              { logo: generalData.technologies[1].logo },
              { logo: generalData.technologies[3].logo },
              { logo: generalData.technologies[4].logo },
              { logo: generalData.technologies[5].logo },
              { logo: generalData.technologies[6].logo },
            ]}
          />
        </FadeService>
        <FadeService visible={inView}>
          <ServiceCard
            title={generalData.services.audio.title}
            text={generalData.services.audio.text}
            tools={[
              { logo: generalData.technologies[10].logo },
              { logo: generalData.technologies[11].logo },
            ]}
          />
        </FadeService>
      </div>
    </div>
  );
}

export default Services;
