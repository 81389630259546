function StudiesCard({ name, location, year, logo }) {
  return (
    <div className="studiescard">
      <div className="picture">
        <img src={logo} className="studies_logo" alt="logo studies" />
      </div>
      <div className="text">
        <h2>{name}</h2>
        <p>{location}</p>
        <p>{year}</p>
      </div>
    </div>
  );
}

export default StudiesCard;
