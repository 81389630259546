import { useState, useEffect, useRef, useMemo } from "react";
import FadeAbout from "../FadeAbout";
import axios from "axios";
import fileDownload from "js-file-download";

function About() {
  const generalData = require("./../../datas/general.json");

  //function dedicated to know if targetRef is visible screen and display it with fade
  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setInView(true);
  };

  const options = useMemo(() => {
    return { root: null, rootMargin: "0px", threshold: 0.5 };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, options]);

  //function to download file

  let handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div className="about" ref={targetRef}>
      <h1>{generalData.about.title}</h1>
      <div className="aboutcontent">
        <div className="textpart">
          <FadeAbout visible={inView}>
            <div id="textforfade">
              <p className="description">{generalData.about.text}</p>
              <button
                id="download-btn"
                onClick={() => {
                  handleDownload(generalData.about.cv, "CV_Thiron_lucas.pdf");
                }}
              >
                Téléchargez mon CV
              </button>
            </div>
          </FadeAbout>
        </div>
        <div className="photopart">
          <FadeAbout visible={inView}>
            <div id="photoforfade">
              <img
                src={generalData.about.picture}
                alt="profilepic"
                id="profilepic"
              />
            </div>
          </FadeAbout>
        </div>
      </div>
    </div>
  );
}

export default About;
