import { HashLink as Link } from "react-router-hash-link";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  //function with lib emailJS combined with environment variables to send an email via the form

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message envoyé !");
        },
        (error) => {
          console.log(error.text);
          alert(
            "Oups ! Il y a un problème avec l'envoi du message. Chargez à nouveau la page et reessayez."
          );
        }
      );
  };

  const generalData = require("./../../datas/general.json");
  return (
    <div className="contact">
      <h1 ud="contact_title">Contactez-moi</h1>
      <div className="content">
        <div className="infodiv">
          <h2>Mes Coordonnées</h2>
          <p>
            {generalData.info.name}
            <br />
            {generalData.info.phone}
            <br />
            {generalData.info.mail}
          </p>
          <div className="info_links">
            <a
              href={generalData.info.github.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={generalData.info.github.logo}
                alt="logo github"
                id="logo_github"
              />
            </a>
            <a
              href={generalData.info.linkedin.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={generalData.info.linkedin.logo}
                alt="logo linkedin"
                id="logo_linkedin"
              />
            </a>
          </div>
        </div>
        <div className="formdiv">
          <form
            action="#"
            ref={form}
            onSubmit={sendEmail}
            className="form"
            method="post"
            id="form"
          >
            <div id="first_line">
              <label>
                Nom
                <input
                  type="text"
                  placeholder="Nom"
                  className="input"
                  id="nom"
                  name="name"
                ></input>
              </label>
              <label>
                Prénom
                <input
                  type="text"
                  placeholder="Prénom"
                  className="input"
                  id="prenom"
                  name="firstname"
                ></input>
              </label>
            </div>
            <label>
              E-mail
              <input
                type="email"
                placeholder="E-mail"
                className="input"
                id="email"
                name="email"
              ></input>
            </label>
            <label>
              Sujet
              <input
                type="text"
                placeholder="Sujet"
                className="input"
                id="sujet"
                name="subject"
              ></input>
            </label>
            <label>
              Message
              <textarea
                type="text"
                placeholder="Votre message"
                className="input"
                id="message"
                name="message"
              ></textarea>
            </label>
            <p>
              Ces données ne sont pas collectées à des fins publicitaires mais
              uniquement pour répondre à vos demandes.
            </p>
            <input type="submit" value="Envoyer" id="submit" />
          </form>
        </div>
      </div>
      <div id="linkhomediv">
        <Link smooth to="/pathLink#homezone" id="linkhome">
          Haut de page
        </Link>
      </div>
    </div>
  );
}

export default Contact;
