import React from "react";
import "./../../utils/style.scss";
import Header from "./../../components/Header";
import About from "./../../components/About";
import Services from "./../../components/Services";
import Studies from "./../../components/Studies";
import Competences from "./../../components/Competences";
import Projects from "./../../components/Projects";
import Contact from "./../../components/Contact";
import Footer from "./../../components/Footer";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Typed from "react-typed";

function Home() {
  const generalData = require("./../../datas/general.json");

  //this is dedicated to smooth the scrolling to each anchor with a threshold, due to header size being sticky
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="global">
      <Header />
      <div className="homecontainer" id="homezone">
        <img
          src={generalData.home.background}
          alt="background intro"
          id="background-image"
        />
        <div className="introduction">
          <h1>
            <Typed
              strings={[`${generalData.home.title}`]}
              typeSpeed={80}
              backSpeed={50}
            />
          </h1>
          <p>{generalData.home.text}</p>
        </div>
        <div className="scrollingfromtop">
          <Link
            smooth
            to="/pathLink#aboutzone"
            scroll={(el) => scrollWithOffset(el)}
            id="linkabout_arrow"
          >
            <FontAwesomeIcon icon={faArrowDown} id="arrow_down_left_icon" />
          </Link>
          <Link
            smooth
            to="/pathLink#projectszone"
            scroll={(el) => scrollWithOffset(el)}
            id="linktravaux"
          >
            Voir mes travaux
          </Link>
          <Link
            smooth
            to="/pathLink#aboutzone"
            scroll={(el) => scrollWithOffset(el)}
            id="linkabout_arrow"
          >
            <FontAwesomeIcon icon={faArrowDown} id="arrow_down_right_icon" />
          </Link>
        </div>
      </div>
      <div className="aboutcontainer" id="aboutzone">
        <About />
      </div>
      <div className="servicescontainer" id="serviceszone">
        <Services />
      </div>
      <div className="studies_competences">
        <img
          src={generalData.home.background_studies}
          alt="background studies et competences"
          id="background-image-studies"
        />
        <div className="filter"></div>
        <div className="studiescontainer" id="studieszone">
          <Studies />
        </div>
        <div className="competencescontainer" id="competenceszone">
          <Competences />
        </div>
      </div>
      <div className="projectscontainer" id="projectszone">
        <Projects />
      </div>
      <div className="contactcontainer" id="contactzone">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
