import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//this inline style is mandatory for this library react-modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "85vh",
    overflow: "auto",
  },
  overlay: { zIndex: 1000 },
};

//This is params for the react-modal library
Modal.setAppElement("#root");

function ProjectModal({ title, year, text, technologies, pictures, link }) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const selectedPictures = pictures.slice(2, 6);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#26495c";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="modal-out">
      <button onClick={openModal} className="modal-btn">
        Ouvrir
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal_in">
          <div className="modal_up_part">
            <div className="up_left">
              <img
                src={pictures[0]}
                alt="responsive design"
                className="responsive_design"
              />
            </div>
            <div className="up_right">
              <button onClick={closeModal} className="modal-close-btn">
                <FontAwesomeIcon icon={faXmark} id="close_icon" />
              </button>
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{title}</h2>
              <h3>Année : {year}</h3>
              <p>{text}</p>
              <h3>Technologies Utilisées</h3>
              <div className="technologies">
                {technologies?.map((technology, id) => (
                  <div className="technology" key={`div ${technology}`}>
                    {technology}
                  </div>
                ))}
              </div>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="project_link"
              >
                Lien vers le code
              </a>
            </div>
          </div>
          <div className="modal_down_part">
            {selectedPictures?.map((selectedpic, id) => (
              <img
                src={selectedpic}
                alt={`illustration ${id}`}
                className="modal_down_capture"
                key={`img ${selectedpic}`}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ProjectModal;
