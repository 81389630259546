import { HashLink as Link } from "react-router-hash-link";
import logo from "./../../assets/logo.svg";

function Header() {
  //this is dedicated to smooth the scrolling to each anchor with a threshold, due to header size being sticky
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="header">
      <div className="logo">
        <img src={logo} alt="logo" id="logo"></img>
      </div>
      <div className="nav">
        <Link
          smooth
          to="/pathLink#homezone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Accueil
        </Link>
        <Link
          smooth
          to="/pathLink#aboutzone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          About
        </Link>
        <Link
          smooth
          to="/pathLink#serviceszone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Services
        </Link>
        <Link
          smooth
          to="/pathLink#studieszone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Etudes
        </Link>
        <Link
          smooth
          to="/pathLink#competenceszone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Competences
        </Link>
        <Link
          smooth
          to="/pathLink#projectszone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Projets
        </Link>
        <Link
          smooth
          to="/pathLink#contactzone"
          scroll={(el) => scrollWithOffset(el)}
          className="navlink"
        >
          Contact
        </Link>
      </div>
    </div>
  );
}

export default Header;
