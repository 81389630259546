import { useState, useEffect, useRef, useMemo } from "react";
import CompetenceCard from "./../CompetenceCard";
import FadeComp from "../FadeComp";

function Competences() {
  const generalData = require("./../../datas/general.json");
  const techList = generalData.technologies;

  //function dedicated to know if targetRef is visible screen and display it with fade

  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setInView(true);
    // setInView(entry.isIntersecting);
  };

  const options = useMemo(() => {
    return { root: null, rootMargin: "0px", threshold: 0.5 };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, options]);

  return (
    <div className="competences" ref={targetRef}>
      <h1>Mes Compétences</h1>
      <div className="competencecards">
        {techList?.map(({ name, logo, index }) => (
          <div key={`div ${name}`}>
            <FadeComp visible={inView}>
              <CompetenceCard name={name} logo={logo} />
            </FadeComp>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Competences;
