function ServiceCard({ title, text, tools }) {
  return (
    <div className="servicesinglecard">
      <h1>{title}</h1>
      <p className="servicestext">{text}</p>

      <div className="servicestools">
        {tools?.map((tool, index) => {
          return (
            <div className="tool" key={`tool ${index}`}>
              <img
                src={tool.logo}
                alt={`logo tool ${index}`}
                className="toollogo"
              ></img>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ServiceCard;
