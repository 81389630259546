import { useState, useEffect, useRef, useMemo } from "react";
import StudiesCard from "./../StudiesCard";
import FadeStudies from "../FadeStudies";

function Studies() {
  const generalData = require("./../../datas/general.json");
  const studiesList = generalData.studies;

  //function dedicated to know if targetRef is visible screen and display it with fade

  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setInView(true);
    // setInView(entry.isIntersecting);
  };

  const options = useMemo(() => {
    return { root: null, rootMargin: "0px", threshold: 0.5 };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, options]);

  return (
    <div className="studies" ref={targetRef}>
      <h1>Mes Etudes</h1>
      <div className="studiescards">
        {studiesList?.map(({ name, location, year, logo, id }) => (
          <div key={`div ${name}`}>
            <FadeStudies visible={inView}>
              <StudiesCard
                name={name}
                location={location}
                year={year}
                logo={logo}
              />
            </FadeStudies>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Studies;
