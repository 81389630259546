function FadeComp({ visible, children }) {
  //this adds class out  to the child when it's visible, in order to make transition in fade
  let className = `fade ${children.props.name}`;
  if (!visible) {
    className += " out";
  }
  return <div className={className}>{children}</div>;
}

export default FadeComp;
