import ProjectCard from "./../ProjectCard";

function Projects() {
  const projectsList = require("./../../datas/projects.json");
  return (
    <div className="projects">
      <h1>Mes Projets</h1>
      <div className="projectscards">
        {projectsList?.map(
          ({ id, title, year, text, technologies, pictures, link }) => (
            <ProjectCard
              title={title}
              year={year}
              text={text}
              technologies={technologies}
              pictures={pictures}
              link={link}
              key={id}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Projects;
