function FadeStudies({ visible, children }) {
  //this adds class out  to the child when it's visible, in order to make transition in fade
  const childrenName = children.props.name.split(" ", 2);
  let className = `fade ${childrenName[0]}`;
  if (!visible) {
    className += " out";
  }
  return <div className={className}>{children}</div>;
}

export default FadeStudies;
